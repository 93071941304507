import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
  Form,
  Tooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
  fetchTicketsListing,
  resetAddTicketForm,
  updateTicketTableAfterUpdate,
  copyTicketDetailsToMetro
} from "../../redux/Tickets/ticketAction";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import { Link } from "react-router-dom";
import { DropdownList } from "react-widgets";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import classnames from "classnames";
import { toast } from "react-toastify";
import StationSelectModal from "../Modal/StationSelectModal";
import ReprintCheckInfoModal from "../Modal/ReprintCheckInfoModal";
import action from "../../assets/img/action.png";
import SetVoidReasonModal from "../Modal/VoidLogger/SetVoidReasonModal";
import qs from "query-string";
import {
  copyTicketDetailsToCoin,
  fetchCoinItemsListingSuccess
} from "../../redux/CoinTicket/coinAction";
import _ from 'underscore';
import ViewAttachment from "views/Modal/ViewAttachment";
import ViewChangeHistory from "views/Modal/ViewChangeHistory";

function MetroTicket(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [dropLoad, setDropLoad] = useState(false);
  const [soldToOptions, setSoldToOptions] = useState([{ name: "None" }]);
  const [signIds, setSignIds] = useState({ data: [] });
  const [sortId, setSortId] = useState("ticketTime");
  const [sortOrder, setSortOrder] = useState("-1");
  const [filterBy, setFilterBy] = useState("Ticket");
  const [changeInterval, setChangeInterval] = useState(false);
  const [stationModal, setStationModal] = useState(false);
  const [stationModalParam, setStationModalParam] = useState({});
  const [reprintModal, setReprintModal] = useState(false);
  const [changeHistoryModal, setChangeHistoryModal] = useState(false);
  const [viewAttachmentModal, setViewAttachmentModal] = useState(false);
  const [todayOnly, setTodayOnly] = useState(true);
  const [emptyProfit, setEmptyProfit] = useState(false);
  const [showName, setShowName] = useState(false);
  const [printId, setPrintId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [voidTooltipOpen, setVoidTooltipOpen] = useState(false);
  const [ticketAttachments, setTicketAttachments] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [ticketDate, setTicketDate] = useState();
  const [ticket, setTicket] = useState({});

  //Hook to get data for sorting
  useEffect(() => {
    document.title = 'Metro Tickets';
    setSignIds({ data: [] });
    let paramQuery = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    let date = paramQuery.date;
    setTicketDate(date);
    let page = paramQuery.page;
    let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
    if (id) {
      setSearch(id);
      props.fetchTicketsListing(
        0,
        id.trim(),
        sortId,
        sortOrder,
        filterBy
      );
    } else {
      if (date) {
        setTodayOnly(false);
        setDateFilter(date.split("-").join("/"));
        
        props.fetchTicketsListing(
          props.ticketDetails.page,
          search,
          sortId,
          sortOrder,
          filterBy,
          date ? false : true,
          emptyProfit,
          date ? date.split("-").join("/") : ""
        );
      } else {
        let filterPage = props.ticketDetails.page;
        let _todayOnly = todayOnly;
        if (page) {
          setTodayOnly(false);
          filterPage = parseInt(page) - 1;
          _todayOnly = false;
        }
        props.fetchTicketsListing(
          filterPage,
          search,
          sortId,
          sortOrder,
          filterBy,
          _todayOnly,
          emptyProfit,
          date ? date.split("-").join("/") : ""
        );
      }
    }
  }, [sortOrder]);

  //_______________if props change on link click(refresh data when change in props for removing date filter)
  if (props.location.search === "" && dateFilter !== "") {
    setDateFilter("");
    props.fetchTicketsListing(
      props.ticketDetails.page,
      search,
      sortId,
      sortOrder,
      filterBy,
      todayOnly,
      emptyProfit,
      ""
    );
  }
  //Change Interval request data
  useEffect(() => {
    let inter;
    let date = qs.parse(props.location.search, { ignoreQueryPrefix: true }).date;
    try {
      inter = setInterval(async () => {
        let params = {
          search: search.trim(),
          page: props.ticketDetails.page,
          sortId,
          sortOrder,
          filterByItem: filterBy === "Ticket" ? 0 : 1,
          filterBy
        };
        //
        if (date) {
          params.dateFilter = date;
        }
        const res = await GetApiActionWithAuthorization("metro-tickets/get-signature-status", params);
        setSignIds({ data: res.data });
      }, 1000 * 60 * 5);
    } catch (e) {
      console.log(e);
    }
    return () => {
      clearInterval(inter);
    };
  }, [changeInterval]);

  //Error Handler from Api and unauthorized check
  if (props.ticketDetails.error && !unauthorized) {
    if (props.ticketDetails.error.status === 403) {
      setUnauthorized(true);
    } else if (props.ticketDetails.error.status === 440) {
      props.resetState();
      toast.error(props.ticketDetails.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleVoidReason = () => setVoidTooltipOpen(!voidTooltipOpen);

  //Change sold to amount handler
  const changeSoldToAmount = (e, total, id) => {
    const { value } = e.target;
    let body = {
      id: id,
      soldToAmount: value,
      profit: parseFloat(value) - parseFloat(total),
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const setProfitFieldEffect = (index) => {
    if (ticketDate) {
      document.getElementById('profit' + index).classList.add('input-profit-focus')
    }
  }

  //Change profit handler
  const changeProfit = (e, index) => {
    let tickets = props.ticketDetails.allTickets
    const { value } = e.target;
    tickets[index].profit = value;
    props.updateTicketTableAfterUpdate(tickets);
    let body = {
      id: tickets[index].id,
      profit: value,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    ).then((res) => {
      //props.updateTicketTableAfterUpdate(res.data);
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  //Change Sold To Handler
  const changeSoldTo = (e, id, ticketAmount) => {
    let body = {
      id: id,
      soldTo: e,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    if (e === "None") {
      body["soldToAmount"] = "0.00";
      body["profit"] = "0.00";
      body["soldTo"] = "";
    }
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //CheckBox change handler
  const checkboxHandler = (e, id) => {
    //const { checked } = e.target;
    let body = {
      id: id,
      paidInFull: document.getElementById("isPaid-" + id).checked ? 1 : 0,
      pulled: document.getElementById("isPulled-" + id).checked ? 1 : 0,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //Change comment handler
  const changeCommentAmount = (e, id) => {
    const { value } = e.target;
    let body = {
      id: id,
      comment: value,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //When sold to open call api to get sold to option (only for first time)
  const toggledSoldTo = (e) => {
    if (e && soldToOptions.length === 1) {
      setDropLoad(true);
      GetApiActionWithAuthorization("common/get-all-buyers/true")
        .then((res) => {
          setDropLoad(false);
          setSoldToOptions(soldToOptions.concat(res.data));
        })
        .catch((err) => {
          setDropLoad(false);
          console.log(err);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  //Sorting Change
  const setSorting = (id) => {
    setChangeInterval(!changeInterval);
    if (id !== sortId) {
      setSortId(id);
      setSortOrder("-1");
    } else {
      setSortOrder(sortOrder === "1" ? "-1" : "1");
    }
  };

  //update redux store when input changed in table
  const updateTableForInputChanges = (e, index) => {
    let value = e.target.value;
    let arr = props.ticketDetails.allTickets;
    let changes = { ...arr[index] };
    changes.soldToAmount = value;
    arr[index] = changes;
    props.updateTicketTableAfterUpdate(arr);
  };

  //Void Change handler
  const markTicketVoidHandler = (isVoid, id, reason = "") => {
    let body = {
      id,
      void: isVoid,
      voidReason: reason,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const sendToLeadsOnline = (index, id) => {
    let data = props.ticketDetails.allTickets;
    data[index].loDisabled = true;
    PostApiWithAuthorizationAction("metro-tickets/post-to-lead-online/" + id)
    .then((res) => {
      toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
    }).catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } else {
        toast.error(err.data, { position: toast.POSITION.TOP_CENTER });
      }
    }).finally(() => {
      data[index].loDisabled = false;
      props.updateTicketTableAfterUpdate(data);
    });
  };

  const changeOneTimeEditStatus = (isEnabled, id) => {
    let body = {
      id: id,
      oneTimeEdit: isEnabled,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const toggleChangeHistory = () => {
    setChangeHistoryModal(!changeHistoryModal);
  };

  const showChangeHistory = (id) => {
    GetApiActionWithAuthorization(`common/get-log-data/${id}`)
      .then((res) => {
        setTicketLogs(res.data);
        setChangeHistoryModal(!changeHistoryModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };


  const printCheckHandler = (ticket) => {
    let body = {
      ticketId: ticket.id,
      ticketType: "metro",
      checkNumber: ticket.checkNumber,
      checkAmount: ticket.paymentCheckAmount,
    };
    PostApiWithAuthorizationAction("common/check-print", body)
      .then((res) => {}).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const printInvoiceHandler = (id, allowSignature = true) => {
    if (localStorage.getItem("lastUsedPrinter")) {
      let body = {
        printerId: localStorage.getItem("lastUsedPrinter"),
        ticketId: id,
        ticketType: "metro",
        allowSignature
      };
      PostApiWithAuthorizationAction("common/print-invoice", body)
        .then((res) => {
          //toast.success(res.message);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    } else {
      toggleStation(id);
      setStationModalParam({ allowSignature });
    }
  };

  const toggleStation = (id = "") => {
    setPrintId(id);
    setStationModal(!stationModal);
  };

  const toggleReprint = (ticket) => {
    if (ticket) {
      setPrintId(ticket.id);
      setTicket(ticket);
    }
    setReprintModal(!reprintModal);
  };

  const toggleViewAttachment = () => {
    setViewAttachmentModal(!viewAttachmentModal);
  };

  const preloadViewAttachment = (id) => {
    GetApiActionWithAuthorization(`metro-tickets/get-particular-ticket-data/${id}`)
      .then((res) => {
        setTicketAttachments(res.data.ticket_attachments);
        setViewAttachmentModal(!viewAttachmentModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const confirmed = (reason) => {
    if (reason) {
      markTicketVoidHandler(true, delId, reason);
      setConfirm(false);
    } else {
      toast.error("Please choose a valid void reason!", { position: toast.POSITION.TOP_CENTER });
    }
  };
  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };
  const copyToNew = (ticket, copyToValue) => {
    ticket.fromx = { name: "repeat" };
    const data = _.pick(ticket, [
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'dOB',
      'height',
      'weight',
      'race',
      'sex',
      'dLState',
      'dLNo',
      'otherIDType',
      'otherIDNo',
      'otherNo',
      'fromx'
    ]);
    if (copyToValue === "ticket") {
      props.copyTicketDetailsToMetro(data);
      props.history.push("/portal/metro-tickets/add-new");
    } else {
      props.fetchCoinItemsListingSuccess({ data: [] });
      props.copyTicketDetailsToCoin(data);
      props.history.push("/portal/coin-tickets/add-new");
    }
  };

  const renderTicketsTableData = (e) => {
    return props.ticketDetails.allTickets.map((tic, i) => {
      return (
        <tr
          key={i}
          className={classnames({
            "no-item": tic.ticket_items.length < 1,
            "void-true": tic.void,
            "no-sign": (!tic.void && (tic.signature === null || tic.signature.length === 0 || (signIds.data.length > 0 && !signIds.data.includes(tic.id))))
          })}
        >
          <td>
            <UncontrolledDropdown id={"action-drop"+i}>
              <DropdownToggle
                id={"drop-ticket"+i}
                className="p-1 bg-transparent border-0"
                disabled={tic.void}>
                <img src={action} width="18px" />
              </DropdownToggle>
              <DropdownMenu className="action-menu">
                <DropdownItem
                  className="noti-title"
                  header
                  tag="div">
                  <h6 className="text-overflow m-0">Actions!</h6>
                </DropdownItem>
                {props.userType === "admin" &&
                  (tic.oneTimeEdit ? (
                    <DropdownItem
                    id="disable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(false, tic.id);
                      }}
                    >
                      <i className="fas fa-toggle-off" />
                      <span>Disable</span>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                    id="enable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(true, tic.id);
                      }}
                    >
                      <i className="fas fa-toggle-on" />
                      <span>Enable</span>
                    </DropdownItem>
                  ))}
                <DropdownItem
                  id="edit-ticket"
                  onClick={(e) =>
                    props.history.push("/portal/metro-tickets/edit/" + tic.id)
                  }>
                  <i className="fas fa-edit"/>
                  <span>Edit</span>
                </DropdownItem>
                {/*props.userType === "admin" && (
                <DropdownItem id="mark-as-void" onClick={(e) => toggleConfirm(tic.id)}>
                  <i className="fas fa-ban" />
                  <span>Void</span>
                </DropdownItem>
                )*/}
                <DropdownItem id="print-invoice" onClick={(e) => printInvoiceHandler(tic.id)}>
                  <i className="fas fa-print" />
                  <span>Print Invoice</span>
                </DropdownItem>
                {props.userType === "admin" && (
                  <DropdownItem
                    onClick={(e) =>
                      props.history.push("/invoice/metro/" + tic.id)
                    }
                  >
                    <i className="fas fa-eye" />
                    <span>View Invoice</span>
                  </DropdownItem>
                )}
                {props.userType === "admin" ? (
                  <>
                    <DropdownItem id = "reprint-check"onClick={(e) => toggleReprint(tic)}>
                      <i className="fas fa-money-check-alt" />
                      <span>Reprint Check</span>
                    </DropdownItem>
                    <DropdownItem
                      id="publish-leadonline-ticket"
                      disabled={tic.loDisabled}
                      onClick={(e) => {
                        sendToLeadsOnline(i, tic.id);
                      }}>
                        <i className="fas fa-paper-plane"></i>
                      <span>Publish To LeadsOnline</span>
                    </DropdownItem>
                    <DropdownItem id="print-invoice" onClick={(e) => showChangeHistory(tic.id)}>
                      <i className="fas fa-history" />
                      <span>Show Changes History</span>
                    </DropdownItem>
                  </>
                ) : (
                  <>
                    <DropdownItem id = "reprint-check"onClick={(e) => printCheckHandler(tic)}>
                      <i className="fas fa-money-check-alt" />
                      <span>Reprint Check</span>
                    </DropdownItem>
                    <DropdownItem
                      id="publish-leadonline-ticket"
                      disabled={tic.loDisabled}
                      onClick={(e) => {
                        sendToLeadsOnline(i, tic.id);
                      }}>
                        <i className="fas fa-paper-plane"></i>
                      <span>Publish To LeadsOnline</span>
                    </DropdownItem>
                  </>
                )}
                <DropdownItem id="copy-to-coin"onClick={(e) => copyToNew(tic, "ticket")}>
                  <i className="far fa-copy"></i>
                  <span>Copy To Metro</span>
                </DropdownItem>
                <DropdownItem id="copy-to-coin"onClick={(e) => copyToNew(tic, "new_coin")}>
                  <i className="far fa-copy"></i>
                  <span>Copy To Coin</span>
                </DropdownItem>
                <DropdownItem id="copy-to-coin"onClick={(e) => printInvoiceHandler(tic.id, false)}>
                  <i className="far fa-copy"></i>
                  <span>Print Invoice (No Signature)</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>
            {tic.void ? (<strike>{tic.id}</strike>) : tic.id}
            <div className="text-center">
              {tic.ticket_attachments.length > 0
                ? (<span
                    className="mr-1 view-attachment-icon"
                    title={tic.ticket_attachments.length + " files"}
                    onClick={(e) => preloadViewAttachment(tic.id)}>
                  <i className="fas fa-paperclip"></i>
                  </span>)
                : ""}
              {signIds.data.includes(parseInt(tic.id)) ? (
                <span className="" title="signed">📝</span>
              ) : (
                tic.signature !== null &&
                tic.signature.length !== 0 && (
                  <span className="" title="signed">📝</span>
                )
              )}
            </div>
          </td>
          <td>{tic.payment}</td>
          <td>
            {tic.void ? (
              <strike>
                <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                  {tic.ticketTime.toString().length === 10
                    ? new Date(tic.ticketTime * 1000)
                    : new Date(tic.ticketTime)}
                </Moment>
              </strike>
            ) : (
              <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                {tic.ticketTime.toString().length === 10
                  ? new Date(tic.ticketTime * 1000)
                  : new Date(tic.ticketTime)}
              </Moment>
            )}
          </td>
          <td className="text-center">
            {tic.void ? (
              <strike>{parseInt(tic.ticketAmount)} </strike>
            ) : (
              parseInt(tic.ticketAmount)
            )}
          </td>
          <td>{tic.void ? <strike>{tic.clerk}</strike> : tic.clerk}</td>
          <td className="text-center">{tic.booth.replace("Booth", "")}</td>
          {showName && (
            <td>
              {tic.void ? (
                <strike>{tic.firstName + " " + tic.lastName}</strike>
              ) : (
                tic.firstName + " " + tic.lastName
              )}
            </td>
          )}
          <td>
            {tic.void ? (
              <strike>
                <a id={"view-items"+i} href={"/portal/metro-tickets/view-items/" + tic.id}>
                  View
                </a>
              </strike>
            ) : (
              <a id={"view-items"+i} href={"/portal/metro-tickets/view-items/" + tic.id}>
                View
              </a>
            )}
          </td>
          {props.userType === "admin" && (
            <>
              {/*<td style={{backgroundColor: tic.profit < 0 ? '#ff8888' : ''}}>
                <Input
                  id={"profit"+i}
                  type="number"
                  readOnly={tic.void}
                  className="input-profit"
                  step="1"
                  value={tic.profit}
                  onChange={(e) =>
                    changeProfit(e, i)
                  }
                  onClick={() =>
                    setProfitFieldEffect(i)
                  }
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                </td>*/}
              <td
                title={
                  tic.soldToB.length > 1
                    ? "Some Items Sold To B go to Items to change"
                    : ""
                }
              >
                <DropdownList
                  id={"soldTo"+i}
                  data={soldToOptions}
                  containerClassName="soldTo-drop"
                  textField="name"
                  placeholder="Select"
                  value={tic.soldTo}
                  onToggle={toggledSoldTo}
                  busy={dropLoad}
                  dropUp={
                    props.ticketDetails.allTickets.length - 2 < i + 1 &&
                    props.ticketDetails.allTickets.length > 2
                  }
                  onChange={(e) =>
                    changeSoldTo(e.name, tic.id, tic.ticketAmount)
                  }
                  disabled={tic.soldToB.length > 1 || tic.void}
                />
              </td>
              <td style={{ whiteSpace: "normal" }}>
                {tic.soldToB.length < 1 ? (
                  <Input
                  id={"profitAmount"+i}
                    type="number"
                    step="1"
                    value={tic.soldToAmount}
                    readOnly={tic.void}
                    placeholder="Amount"
                    disabled={props.userType !== "admin"}
                    onChange={(e) => updateTableForInputChanges(e, i)}
                    onBlur={(e) =>
                      changeSoldToAmount(e, tic.ticketAmount, tic.id)
                    }
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                ) : (
                  <h6 title="Some Items Sold To B">Go to Items to change</h6>
                )}
              </td>
              <td>
                <input
                  id={"isPulled-"+tic.id}
                  disabled={tic.void}
                  type="checkbox"
                  checked={tic.pulled}
                  onChange={(e) => checkboxHandler(e, tic.id)}
                />
                <small className="ml-2"></small>
              </td>
              <td>
                <input
                  id={"isPaid-"+tic.id}
                  disabled={tic.void}
                  type="checkbox"
                  checked={tic.paidInFull}
                  onChange={(e) => checkboxHandler(e, tic.id)}
                />
                <small className="ml-2"></small>
              </td>
              <td>
                {(tic.invoiceNum > 0) && (
                  <a id={"view-invoice"+i} href={"/invoices/v/" + tic.invoiceNum}>
                    {tic.invoiceNum}
                  </a>
                )}
              </td>
            </>
          )}
          <td>
            <Input
              id={"comment"+i}
              type="text"
              defaultValue={tic.comment}
              readOnly={tic.void}
              placeholder="Comment"
              onBlur={(e) => changeCommentAmount(e, tic.id)}
            />
          </td>
        </tr>
      );
    });
  };
  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Metro Tickets {dateFilter && `(${dateFilter})`}</h1>
            </div>
          </CardHeader>
          <CardBody className={ticketDate ? 'page-date-main-content' : 'page-main-content'}>
            <Row className="align-items-center search-container">
              <Col md="5" lg="6" xl="7" className="search-left-btn">
                <a
                  href="/portal/metro-tickets/add-new"
                  onClick={(e) => props.resetForm()}
                >
                  <Button id="create-ticket" color="primary">
                    Create New Ticket
                  </Button>
                </a>
              </Col>
              <Col md="7" lg="6" xl="5">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setShowName(true);
                    } else {
                      setShowName(false);
                    }
                    setChangeInterval(!changeInterval);
                    props.fetchTicketsListing(
                      0,
                      search.trim(),
                      sortId,
                      sortOrder,
                      filterBy
                    );
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder={
                      filterBy === "Ticket"
                        ? "Search by id,ticket amount,first name,last name,clerk.etc"
                        : (filterBy == "Ticket Items") ? "Search by items description" : "Search by ticket or items details"
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <DropdownList
                    data={["Ticket", "Ticket Items", "All"]}
                    containerClassName="filter-By"
                    value={filterBy}
                    placeholder="Select"
                    onChange={(e) => setFilterBy(e)}
                  />
                  <Button type="submit" className="mx-2" color="primary">
                    Search
                  </Button>
                </Form>
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-item-center">
              {props.userType === "admin" ? (
                <FormGroup className="mt-4 form-control-label" check>
                  <Label className="form-control-label" check>
                    <Input
                      type="checkbox"
                      id="todayOnly"
                      checked={todayOnly}
                      onChange={(e) => {
                        setSignIds({ data: [] });
                        setTodayOnly(e.target.checked);
                        props.fetchTicketsListing(
                          props.ticketDetails.page,
                          search,
                          sortId,
                          sortOrder,
                          filterBy,
                          e.target.checked,
                          emptyProfit,
                          dateFilter
                        );
                      }}
                    />{" "}
                    Today Only
                  </Label>
                </FormGroup>
              ) : (
                <div></div>
              )}
              {props.userType === "admin" && (
                <FormGroup className="mt-4 form-control-label" check>
                  <Label className="form-control-label" check>
                    <Input
                      type="checkbox"
                      id="emptyProfit"
                      checked={emptyProfit}
                      onChange={(e) => {
                        setSignIds({ data: [] });
                        setEmptyProfit(e.target.checked);
                        props.fetchTicketsListing(
                          props.ticketDetails.page,
                          search,
                          sortId,
                          sortOrder,
                          filterBy,
                          todayOnly,
                          e.target.checked,
                          dateFilter
                        );
                      }}
                    />{" "}
                    Show Empty Profit
                  </Label>
                </FormGroup>
              )}
              <div className="d-flex align-item-center justify-content-end mt-4">
                <div className="void-true color-info"></div>
                <h6 className="mx-1">Void Ticket</h6>
                <div className="no-sign color-info"></div>
                <h6 className="mx-1">No signature</h6>
                <div className="no-item color-info"></div>
                <h6 className="ml-1">No items in ticket</h6>
              </div>
            </div>
            <Table
              className={(ticketDate ? 'ticket-date-table' : '') + ' ticket-table table-listing'}
              responsive="md"
              bordered
              striped>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("id")}
                  >
                    Ticket{" "}
                    {sortId === "id" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th>Pay</th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("ticketTime")}
                  >
                    Date{" "}
                    {sortId === "ticketTime" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("ticketAmount")}
                  >
                    Ticket Amount{" "}
                    {sortId === "ticketAmount" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th scope="col">Clerk</th>
                  <th scope="col" style={{width: '70px'}}>Booth</th>
                  {showName && <th scope="col">Name</th>}
                  <th scope="col">Desc.</th>
                  {props.userType === "admin" && (
                    <>
                      {/*<th
                        scope="col"
                        className="pointer"
                        onClick={(e) => setSorting("profit")}
                      >
                        Profit{" "}
                        {sortId === "profit" &&
                          (sortOrder === "-1" ? (
                            <i className="fas fa-chevron-circle-down"></i>
                          ) : (
                            <i className="fas fa-chevron-circle-up"></i>
                          ))}
                          </th>*/}
                      <th scope="col">Sold To</th>
                      <th
                        scope="col"
                        className="pointer"
                        onClick={(e) => setSorting("soldToAmount")}
                      >
                        Amount{" "}
                        {sortId === "soldToAmount" &&
                          (sortOrder === "-1" ? (
                            <i className="fas fa-chevron-circle-down"></i>
                          ) : (
                            <i className="fas fa-chevron-circle-up"></i>
                          ))}
                      </th>
                      <th scope="col">Pulled</th>
                      <th scope="col">Paid</th>
                      <th scope="col">Invoice</th>
                    </>
                  )}
                  <th scope="col" style={{width: '200px'}}>Comment</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.ticketDetails.allTickets.length > 0 &&
              props.userType === "admin" && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.ticketDetails.page}
                  pageCount={props.ticketDetails.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    //run only when page changes
                    if (props.ticketDetails.page !== e.selected) {
                      setSignIds({ data: [] });
                      setChangeInterval(!changeInterval);
                      props.fetchTicketsListing(
                        e.selected,
                        search,
                        sortId,
                        sortOrder,
                        filterBy,
                        todayOnly,
                        emptyProfit,
                        dateFilter
                      );
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
          </CardBody>
        </Card>
      </Container>
      {stationModal && (
        <StationSelectModal
          show={stationModal}
          toggle={toggleStation}
          data={{ ...stationModalParam, id: printId, type: "metro" }}
        />
      )}
      {confirm && (
        <SetVoidReasonModal
          show={confirm}
          message={<h4>Please tell us why you want to mark this ticket as "VOID".</h4>}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
      {reprintModal && (
        <ReprintCheckInfoModal
          show={reprintModal}
          toggle={toggleReprint}
          data={{ ...ticket, id: printId, type: "metro" }}
        />
      )}
      {viewAttachmentModal && (
        <ViewAttachment
          show={viewAttachmentModal}
          toggle={toggleViewAttachment}
          data={ticketAttachments}
        />
      )}
      {changeHistoryModal && (
        <ViewChangeHistory
          show={changeHistoryModal}
          toggle={toggleChangeHistory}
          data={ticketLogs}
          viewType="ticket"
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ticketDetails: state.ticketReducer.tickets,
    userType: state.loginUserReducer.userType,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTicketsListing: (
      body,
      search,
      sortId,
      sortOrder,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter
    ) =>
      dispatch(
        fetchTicketsListing(
          body,
          search,
          sortId,
          sortOrder,
          filterBy,
          todayOnly,
          emptyProfit,
          dateFilter
        )
      ),
    updateTicketTableAfterUpdate: (body) => dispatch(updateTicketTableAfterUpdate(body)),
    copyTicketDetailsToCoin: (body) => dispatch(copyTicketDetailsToCoin(body)),
    copyTicketDetailsToMetro: (data) => dispatch(copyTicketDetailsToMetro(data)),
    fetchCoinItemsListingSuccess: (body) =>
    dispatch(fetchCoinItemsListingSuccess(body)),
    resetState: () => dispatch(resetState()),
    resetForm: () => dispatch(resetAddTicketForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetroTicket);
