import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
  fetchCoinsListing,
  updateTicketTableAfterUpdate,
  fetchCoinItemsListing,
  fetchCoinItemsListingSuccess,
  resetAddCoinForm,
  copyTicketDetailsToCoin,
  saveCoinItemDetails,
} from "../../redux/CoinTicket/coinAction";

import { copyTicketDetailsToMetro } from "../../redux/Tickets/ticketAction";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import Unauthorized from "views/Pages/Unauthorized";
import ViewAndEditCoinItemsModal from "views/Modal/CoinTicketsModals/viewAndEditCoinItemsModal";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import classnames from "classnames";
import { DropdownList } from "react-widgets";
import { toast } from "react-toastify";
import StationSelectModal from "../Modal/StationSelectModal";
import ReprintCheckInfoModal from "views/Modal/ReprintCheckInfoModal";
import action from "../../assets/img/action.png";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import qs from "query-string";
import _ from "underscore";
import '../../assets/css/coin.css'
import ViewAttachment from "views/Modal/ViewAttachment";
import SetVoidReasonModal from "views/Modal/VoidLogger/SetVoidReasonModal";
import ViewChangeHistory from "views/Modal/ViewChangeHistory";

function CoinTicket(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [viewAttachmentModal, setViewAttachmentModal] = useState(false);
  const [id, setId] = useState("");
  const [signIds, setSignIds] = useState({ data: [] });
  const [sortId, setSortId] = useState("id");
  const [sortOrder, setSortOrder] = useState("-1");
  const [filterBy, setFilterBy] = useState("Ticket");
  const [changeInterval, setChangeInterval] = useState(false);
  const [stationModal, setStationModal] = useState(false);
  const [stationModalParam, setStationModalParam] = useState({});
  const [reprintModal, setReprintModal] = useState(false);
  const [printId, setPrintId] = useState("");
  const [todayOnly, setTodayOnly] = useState(true);
  const [emptyProfit, setEmptyProfit] = useState(false);
  const [showName, setShowName] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [ticketAttachments, setTicketAttachments] = useState([]);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [changeHistoryModal, setChangeHistoryModal] = useState(false);
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    setSignIds({ data: [] });
    let date = qs.parse(props.location.search, { ignoreQueryPrefix: true }).date;
    let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
    if (id) {
      setSearch(id);
      props.fetchCoinsListing(
        0,
        id.trim(),
        sortId,
        sortOrder,
        filterBy
      );
    } else {
      if (date) {
        setTodayOnly(false);
        setDateFilter(date.split("-").join("/"));
        props.fetchCoinsListing(
          props.ticketDetails.page,
          search,
          sortId,
          sortOrder,
          filterBy,
          date ? false : true,
          emptyProfit,
          date ? date.split("-").join("/") : ""
        );
      } else {
        props.fetchCoinsListing(
          props.ticketDetails.page,
          search,
          sortId,
          sortOrder,
          filterBy,
          todayOnly,
          emptyProfit,
          date ? date.split("-").join("/") : ""
        );
      }
    }
  }, [sortOrder]);

  //_______________if props change on link click(refresh data when change in props for removing date filter)
  if (props.location.search === "" && dateFilter !== "") {
    setDateFilter("");
    props.fetchCoinsListing(
      props.ticketDetails.page,
      search,
      sortId,
      sortOrder,
      filterBy,
      todayOnly,
      emptyProfit,
      ""
    );
  }

  //Change Interval request data
  useEffect(() => {
    let inter;
    let date = qs.parse(props.location.search, { ignoreQueryPrefix: true }).date;
    try {
      inter = setInterval(async () => {
        let params =           {
          search: search.trim(),
          page: props.ticketDetails.page,
          sortId,
          sortOrder,
          filterByItem: filterBy === "Ticket" ? 0 : 1,
        };
        //
        if (date) {
          params.dateFilter = date;
        }
        const res = await GetApiActionWithAuthorization("coin-tickets/get-signature-status", params).catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
        setSignIds({ data: res.data });
      }, 1000 * 60 * 5);
    } catch (e) {
      console.log(e);
    }
    return () => {
      clearInterval(inter);
    };
  }, [changeInterval]);

  if (props.ticketDetails.error && !unauthorized) {
    if (props.ticketDetails.error.status === 403) {
      setUnauthorized(true);
    } else if (props.ticketDetails.error.status === 440) {
      props.resetState();
      toast.error(props.ticketDetails.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }
  const editModalToggle = (e_id = "") => {
    setId(e_id);
    props.fetchCoinItemsListingSuccess({ data: [] });
    setEditModal(!editModal);
  };

  const toggleChangeHistory = () => {
    setChangeHistoryModal(!changeHistoryModal);
  };

  //Change comment handler
  const changeProfit = (e, id) => {
    const { value } = e.target;
    let body = {
      id: id,
      profit: value ? value : 0,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "coin-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        //props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const setSorting = (id) => {
    setChangeInterval(!changeInterval);
    if (id !== sortId) {
      setSortId(id);
      setSortOrder("-1");
    } else {
      setSortOrder(sortOrder === "1" ? "-1" : "1");
    }
  };

  const updateTableForInputChanges = (e, index) => {
    let value = e.target.value;
    let arr = props.ticketDetails.allTickets;
    let changes = { ...arr[index] };
    changes.profit = value;
    arr[index] = changes;
    props.updateTicketTableAfterUpdate(arr);
  };

  const printInvoiceHandler = (id, allowSignature = true) => {
    if (localStorage.getItem("lastUsedPrinter")) {
      let body = {
        printerId: localStorage.getItem("lastUsedPrinter"),
        ticketId: id,
        ticketType: "newCoin",
        allowSignature
      };
      PostApiWithAuthorizationAction("common/print-invoice", body)
        .then((res) => {
          //toast.success(res.message);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    } else {
      toggleStation(id);
      setStationModalParam({ allowSignature });
    }
  };

  const toggleStation = (id = "") => {
    setPrintId(id);
    setStationModal(!stationModal);
  };

  const toggleViewAttachment = () => {
    setViewAttachmentModal(!viewAttachmentModal);
  };

  const preloadViewAttachment = (id) => {
    GetApiActionWithAuthorization(`coin-tickets/get-particular-ticket-data/${id}`)
      .then((res) => {
        setTicketAttachments(res.data.new_coin_attachments);
        setViewAttachmentModal(!viewAttachmentModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //Change comment handler
  const changeCommentAmount = (e, id) => {
    const { value } = e.target;
    let body = {
      id: id,
      comment: value,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "coin-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //Void Change handler
  const markTicketVoidHandler = (isVoid, id, reason = "") => {
    let body = {
      id,
      void: isVoid,
      voidReason: reason,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "coin-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.updateTicketTableAfterUpdate(res.data);
        setDelId("");
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const changeOneTimeEditStatus = (isEnabled, id) => {
    let body = {
      id: id,
      oneTimeEdit: isEnabled,
      page: props.ticketDetails.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly,
      emptyProfit,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "coin-tickets/edit-ticket-render-table-data",
      body
    )
      .then((res) => {
        props.updateTicketTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  const copyToNew = (ticket, copyToValue) => {
    ticket.fromx = { name: "repeat" };
    const data = _.pick(ticket, [
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'dOB',
      'height',
      'weight',
      'race',
      'sex',
      'dLState',
      'dLNo',
      'otherIDType',
      'otherIDNo',
      'otherNo',
      'fromx'
    ]);
    if (copyToValue === "ticket") {
      props.copyTicketDetailsToMetro(data);
      props.history.push("/portal/metro-tickets/add-new");
    } else {
      props.fetchCoinItemsListingSuccess({ data: [] });
      props.copyTicketDetailsToCoin(data);
      props.history.push("/portal/coin-tickets/add-new");
    }
  };

  const showChangeHistory = (id) => {
    GetApiActionWithAuthorization(`common/get-log-data/${id}`)
      .then((res) => {
        setTicketLogs(res.data);
        setChangeHistoryModal(!changeHistoryModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const toggleReprint = (ticket) => {
    if (ticket) {
      setPrintId(ticket.id);
      setTicket(ticket);
    }
    setReprintModal(!reprintModal);
  };

  const confirmed = (reason) => {
    if (reason) {
      markTicketVoidHandler(true, delId, reason);
      setConfirm(false);
    } else {
      toast.error("Please choose a valid void reason!", { position: toast.POSITION.TOP_CENTER });
    }
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const printCheckHandler = (ticket) => {
    let body = {
      ticketId: ticket.id,
      ticketType: "coin",
      checkNumber: ticket.checkNumber,
      checkAmount: ticket.paymentCheckAmount,
    };
    PostApiWithAuthorizationAction("common/check-print", body)
      .then((res) => {}).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    return props.ticketDetails.allTickets.map((tic, i) => {
      let ticketProfit = (tic.profit == 0) ? "" : tic.profit;
      return (
        <tr
          key={i}
          className={classnames({
            "no-item": tic.inventories.length < 1,
            "void-true": tic.void,
            "no-sign": (!tic.void && (tic.signature === null || tic.signature.length === 0 || (signIds.data.length > 0 && !signIds.data.includes(tic.id))))
          })}
        >
          <td>
            <UncontrolledDropdown id={"action-drop" + i}>
              <DropdownToggle
                id={"drop-ticket" + i}
                className="p-1 bg-transparent border-0"
                disabled={tic.void}
              >
                <img src={action} width="18px" />
              </DropdownToggle>
              <DropdownMenu className="action-menu">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Actions!</h6>
                </DropdownItem>
                {props.userType === "admin" &&
                  (tic.oneTimeEdit ? (
                    <DropdownItem
                      id="disable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(false, tic.id);
                      }}
                    >
                      <i className="fas fa-toggle-off" />
                      <span>Disable</span>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      id="enable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(true, tic.id);
                      }}
                    >
                      <i className="fas fa-toggle-on" />
                      <span>Enable</span>
                    </DropdownItem>
                  ))}
                <DropdownItem
                  id="edit-ticket"
                  onClick={(e) =>
                    props.history.push("/portal/coin-tickets/edit/" + tic.id)
                  }
                >
                  <i className="fas fa-edit" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem
                  id="print-invoice"
                  onClick={(e) => printInvoiceHandler(tic.id)}
                >
                  <i className="fas fa-print" />
                  <span>Print Invoice</span>
                </DropdownItem>
                {props.userType === "admin" && (
                  <DropdownItem
                    onClick={(e) =>
                      props.history.push("/invoice/coin/" + tic.id)
                    }
                  >
                    <i className="fas fa-eye" />
                    <span>View Invoice</span>
                  </DropdownItem>
                )}
                {/*
                {(props.userType === "admin" && tic.void) && (
                  <DropdownItem disabled={true}>
                    <i className="fas fa-ban" />
                    <span>It's a Void Ticket</span>
                  </DropdownItem>
                )}
                {(props.userType === "admin" && !tic.void) && (
                  <DropdownItem onClick={(e) => toggleConfirm(tic.id)}>
                    <i className="fas fa-ban" />
                    <span>Void</span>
                  </DropdownItem>
                )}*/}           
                {props.userType === "admin" ? (
                  <>
                    <DropdownItem
                      id="reprint-check"
                      onClick={(e) => toggleReprint(tic)}
                    >
                      <i className="fas fa-money-check-alt" />
                      <span>Reprint Check</span>
                    </DropdownItem>
                    <DropdownItem
                      id="print-invoice"
                      onClick={(e) => showChangeHistory(tic.id)}>
                      <i className="fas fa-history" />
                      <span>Show Changes History</span>
                    </DropdownItem>
                  </>
                ) : (
                  <DropdownItem
                    id="reprint-check"
                    onClick={(e) => printCheckHandler(tic)}>
                    <i className="fas fa-money-check-alt" />
                    <span>Reprint Check</span>
                  </DropdownItem>
                )}
                <DropdownItem id="copy-to-coin"onClick={(e) => copyToNew(tic, "ticket")}>
                  <i class="far fa-copy"></i>
                  <span>Copy To Metro</span>
                </DropdownItem>
                <DropdownItem id="copy-to-coin"onClick={(e) => copyToNew(tic, "new_coin")}>
                  <i class="far fa-copy"></i>
                  <span>Copy To Coin</span>
                </DropdownItem>
                <DropdownItem id="copy-to-coin"onClick={(e) => printInvoiceHandler(tic.id, false)}>
                  <i class="far fa-copy"></i>
                  <span>Print Invoice (No Signature)</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
          <td>
            {tic.void ? <strike>{tic.id}</strike> : tic.id}
            <div className="text-left">
              {tic.new_coin_attachments.length > 0
                ? (<span
                    className="mr-1 view-attachment-icon"
                    title={tic.new_coin_attachments.length + " files"}
                    onClick={(e) => preloadViewAttachment(tic.id)}>
                  <i class="fas fa-paperclip"></i>
                  </span>)
                : ""}
              {signIds.data.includes(parseInt(tic.id)) ? (
                <span class="" title="signed">📝</span>
              ) : (
                tic.signature.length > 0 && (
                  <span class="" title="signed">📝</span>
                )
              )}
            </div>
          </td>
          <td>{tic.payment}</td>
          <td>
            {tic.void ? (
              <strike>
                <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                  {tic.ticketTime.toString().length === 10
                    ? tic.ticketTime * 1000
                    : tic.ticketTime}
                </Moment>
              </strike>
            ) : (
              <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                {tic.ticketTime.toString().length === 10
                  ? tic.ticketTime * 1000
                  : tic.ticketTime}
              </Moment>
            )}
          </td>
          <td className="text-center">
            {tic.void ? (
              <strike>{parseInt(tic.ticketAmount)}</strike>
            ) : (
              parseInt(tic.ticketAmount)
            )}
          </td>
          <td>{tic.void ? <strike>{tic.clerk}</strike> : tic.clerk}</td>
          <td className="text-center">{tic.booth.toLowerCase().replace("booth", "")}</td>
          {showName && (
            <td>
              {tic.void ? (
                <strike>{tic.firstName + " " + tic.lastName}</strike>
              ) : (
                tic.firstName + " " + tic.lastName
              )}
            </td>
          )}
          <td>
            {tic.void ? (
              <strike>
                <a
                  href="#"
                  id={"view-items" + i}
                  onClick={(e) => editModalToggle(tic.id)}
                >
                  View
                </a>
              </strike>
            ) : (
              <a
                href="#"
                id={"view-items" + i}
                onClick={(e) => editModalToggle(tic.id)}
              >
                View
              </a>
            )}
          </td>
          {/*props.userType === "admin" && (
            <td>
              <Input
                id={"profitAmount" + i}
                type="number"
                key={i}
                value={ticketProfit}
                disabled={!["admin", "employee"].includes(props.userType)}
                placeholder="Profit"
                onChange={(e) => updateTableForInputChanges(e, i)}
                onBlur={(e) => changeProfit(e, tic.id)}
                onClick={(e) => {
                  if (e.target.value == 0) {
                    let arr = props.ticketDetails.allTickets;
                    let changes = { ...arr[i] };
                    changes.profit = "";
                    arr[i] = changes;
                    props.updateTicketTableAfterUpdate(arr);
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </td>
              )*/}
          <td>
            <Input
              id={"comment" + i}
              type="text"
              defaultValue={tic.comment}
              placeholder="Comment"
              onBlur={(e) => changeCommentAmount(e, tic.id)}
            />
          </td>
        </tr>
      );
    });
  };
  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow card-coin">
          <CardHeader className="bg-grn">
            <div className="text-center">
              <h1 className="text-white">
                Coin Tickets {dateFilter && `(${dateFilter})`}
              </h1>
            </div>
          </CardHeader>
          <CardBody className="bg-grn page-main-content">
            <Row className="align-items-center search-container">
              <Col md="5" lg="6" xl="7" className="search-left-btn">
                <a
                  id="create-ticket"
                  href="/portal/coin-tickets/add-new"
                  onClick={(e) => props.resetForm()}
                >
                  <Button color="primary">Create New Ticket</Button>
                </a>
              </Col>
              <Col md="7" lg="6" xl="5">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search.trim()) {
                      setShowName(true);
                    } else {
                      setShowName(false);
                    }
                    setChangeInterval(!changeInterval);
                    props.fetchCoinsListing(
                      0,
                      search.trim(),
                      sortId,
                      sortOrder,
                      filterBy
                    );
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder={
                      filterBy === "Ticket"
                        ? "Search by id,ticket amount,first name,last name,clerk.etc"
                        : "Search by items description"
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <DropdownList
                    data={["Ticket", "Ticket Items"]}
                    containerClassName="filter-By"
                    value={filterBy}
                    placeholder="Select"
                    onChange={(e) => setFilterBy(e)}
                  />
                  <Button
                    type="submit"
                    className="mx-2"
                    color="primary"
                    // onClick={(e) => {
                    //   }
                  >
                    Search
                  </Button>
                </Form>
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-item-center">
                <FormGroup className="mt-4 form-control-label" check>
                  <Label
                    className="form-control-label"
                    className="text-white"
                    check
                  >
                    <Input
                      type="checkbox"
                      id="todayOnly"
                      checked={todayOnly}
                      onChange={(e) => {
                        setSignIds({ data: [] });
                        setTodayOnly(e.target.checked);
                        props.fetchCoinsListing(
                          props.ticketDetails.page,
                          search,
                          sortId,
                          sortOrder,
                          filterBy,
                          e.target.checked,
                          emptyProfit,
                          dateFilter
                        );
                      }}
                    />{" "}
                    Today Only
                  </Label>
                </FormGroup>

              <FormGroup className="mt-4 form-control-label" check>
                <Label
                  className="form-control-label"
                  className="text-white"
                  check
                >
                  <Input
                    type="checkbox"
                    id="emptyProfit"
                    checked={emptyProfit}
                    onChange={(e) => {
                      setSignIds({ data: [] });
                      setEmptyProfit(e.target.checked);
                      setTodayOnly(false);
                      props.fetchCoinsListing(
                        props.ticketDetails.page,
                        search,
                        sortId,
                        sortOrder,
                        filterBy,
                        false,
                        e.target.checked,
                        dateFilter
                      );
                    }}
                  />{" "}
                  Show Empty Profit
                </Label>
              </FormGroup>

              <div className="d-flex align-item-center justify-content-end mt-4">
                <div className="void-true color-info"></div>
                <h6 className="mx-1 text-white">Void Ticket</h6>
                <div className="no-sign color-info "></div>
                <h6 className="mx-1 text-white">No signature</h6>
                <div className="no-item color-info"></div>
                <h6 className="ml-1 text-white">No items in ticket</h6>
              </div>
            </div>
            <Table className="ticket-table table-listing" responsive="md" bordered striped>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("id")}
                  >
                    Ticket{" "}
                    {sortId === "id" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th>Pay</th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("ticketTime")}
                  >
                    Date{" "}
                    {sortId === "ticketTime" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th
                    scope="col"
                    className="pointer"
                    onClick={(e) => setSorting("ticketAmount")}
                  >
                    Ticket Amount{" "}
                    {sortId === "ticketAmount" &&
                      (sortOrder === "-1" ? (
                        <i className="fas fa-chevron-circle-down"></i>
                      ) : (
                        <i className="fas fa-chevron-circle-up"></i>
                      ))}
                  </th>
                  <th scope="col">Clerk</th>
                  <th scope="col" style={{width: '70px'}}>Booth</th>
                  {showName && <th scope="col">Name</th>}
                  <th scope="col">Desc.</th>
                  {/*props.userType === "admin" && (
                    <th
                      scope="col"
                      className="pointer"
                      onClick={(e) => setSorting("profit")}
                      style={{width: '120px'}}
                    >
                      Profit{" "}
                      {sortId === "profit" &&
                        (sortOrder === "-1" ? (
                          <i className="fas fa-chevron-circle-down"></i>
                        ) : (
                          <i className="fas fa-chevron-circle-up"></i>
                        ))}
                    </th>
                    )*/}
                  <th scope="col">Comment</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.ticketDetails.allTickets.length > 0 && (
                <ReactPaginate
                  previousLabel={
                    <i className="fas fa-chevron-left text-white"></i>
                  }
                  nextLabel={
                    <i className="fas fa-chevron-right text-white"></i>
                  }
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.ticketDetails.page}
                  pageCount={props.ticketDetails.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (props.ticketDetails.page !== e.selected) {
                      setSignIds({ data: [] });
                      setChangeInterval(!changeInterval);
                      props.fetchCoinsListing(
                        e.selected,
                        search,
                        sortId,
                        sortOrder,
                        filterBy,
                        todayOnly,
                        emptyProfit,
                        dateFilter
                      );
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
          </CardBody>
        </Card>
      </Container>
      {stationModal && (
        <StationSelectModal
          show={stationModal}
          toggle={toggleStation}
          data={{ ...stationModalParam, id: printId, type: "newCoin" }}
        />
      )}

      {confirm && (
        <SetVoidReasonModal
          show={confirm}
          message={<h4>Please tell us why you want to mark this ticket as "VOID".</h4>}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}

      {editModal && (
        <ViewAndEditCoinItemsModal
          {...props}
          show={editModal}
          isBarter={false}
          id={id}
          toggle={editModalToggle}
        />
      )}

      {reprintModal && (
        <ReprintCheckInfoModal
          show={reprintModal}
          toggle={toggleReprint}
          data={{ ...ticket, id: printId, type: "coin" }}
        />
      )}

      {viewAttachmentModal && (
        <ViewAttachment
          show={viewAttachmentModal}
          toggle={toggleViewAttachment}
          data={ticketAttachments}
        />
      )}

      {changeHistoryModal && (
        <ViewChangeHistory
          show={changeHistoryModal}
          toggle={toggleChangeHistory}
          data={ticketLogs}
          viewType="ticket"
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ticketDetails: state.coinReducer.coin_tickets,
    userType: state.loginUserReducer.userType,
    loading: state.commonReducer.loading,
    items: state.coinReducer.coinItemdetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCoinsListing: (
      page,
      search,
      sortId,
      order,
      filterBy,
      todayOnly,
      emptyProfit,
      dateFilter
    ) =>
      dispatch(
        fetchCoinsListing(
          page,
          search,
          sortId,
          order,
          filterBy,
          todayOnly,
          emptyProfit,
          dateFilter
        )
      ),
    fetchCoinItemsListing: (id) => dispatch(fetchCoinItemsListing(id)),
    copyTicketDetailsToMetro: (data) =>
      dispatch(copyTicketDetailsToMetro(data)),
    copyTicketDetailsToCoin: (data) => dispatch(copyTicketDetailsToCoin(data)),
    updateTicketTableAfterUpdate: (body) =>
      dispatch(updateTicketTableAfterUpdate(body)),
    saveCoinItemDetails: (body) => dispatch(saveCoinItemDetails(body)),
    fetchCoinItemsListingSuccess: (body) =>
      dispatch(fetchCoinItemsListingSuccess(body)),
    resetForm: () => dispatch(resetAddCoinForm()),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinTicket);
