import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DropdownList } from "react-widgets";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import {
  fetchTicketItemsListing,
  fetchTicketItemsListingSuccess,
  copyTicketDetailsToMetro,
} from "redux/Tickets/ticketAction";
import { copyTicketDetailsToCoin } from "../../../redux/CoinTicket/coinAction";

import styled from "styled-components";
import Header from "components/Headers/Header";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { resetState } from "../../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import AddMetroItems from "views/Forms/AddMetroItems/AddMetroItems";
import ViewOnlyItems from "./ViewOnlyItems";
import ItemsEditableTable from "./ItemsEditableTable";
import {
  stationOptions,
  copyToData,
  raceOptions,
  fromOptions,
  sexOptions,
} from "../../../constants/ticketConstants";
import BlockModal from "views/Modal/BlockModal";
import FormGroup from "reactstrap/lib/FormGroup";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import qs from 'query-string';

function ViewItems(props) {
  const [dropLoad, setDropLoad] = useState(false);
  const [soldToOptions, setSoldToOptions] = useState([{ name: "None" }]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [tableEditable, setTableEditable] = useState(false);
  const [copies, setCopies] = useState("");
  const [copiesB, setCopiesB] = useState("");
  const [station, setStation] = useState("");
  const [stationB, setStationB] = useState("");
  const [printLabelType, setPrintLabeltype] = useState(false);
  const [copyTo, setCopyTo] = useState({ value: "ticket", label: "Ticket" });
  const [blockModal, setBlockModal] = useState(false);
  const [soldToAmount, setSoldToAmount] = useState("");
  const [firstEditSoldToAmount, setFirstEditSoldToAmount] = useState(true);
  const [soldToAmountB, setSoldToAmountB] = useState("");
  const [firstEditSoldToAmountB, setFirstEditSoldToAmountB] = useState(true);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("Weight");
  const [confirm, setConfirm] = useState(false);
  const [highlightId, setHighlightId] = useState(null);

  useEffect(() => {
    let id = props.match.params.id;
    props.fetchTicketItemsListing(id);
    let itemId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).itemId; 
    // highlight id
    setHighlightId(itemId);
    setTimeout(() => {
      let element = document.getElementById("row-" + itemId);
      if (element) {
        window.scrollTo(0, element.offsetTop);
      }
    }, 3000);
    //
    setStation(
      stationOptions.find(
        (e) => e.value === localStorage.getItem("lastUsedPrinter")
      )
    );
    //
    setStationB(
      stationOptions.find(
        (e) => e.value === localStorage.getItem("lastUsedPrinter")
      )
    );
  }, []);

  useEffect(() => {
    setSoldToAmount(props.items.ticketDetails.soldToAmount);
    if(props.items.ticketDetails.soldToAmount > 0) {
      setFirstEditSoldToAmount(false);
    }
    setSoldToAmountB(props.items.ticketDetails.soldToAmountB);
    if(props.items.ticketDetails.soldToAmountB > 0) {
      setFirstEditSoldToAmountB(false);
    }
  }, [
    props.items.ticketDetails.soldToAmount,
    props.items.ticketDetails.soldToAmountB,
  ]);

  const changeSoldToHandlerA = (e) => {
    let anySoldToAChecked = document.querySelectorAll('input[name="a"]:checked').length;
    let anySoldToBChecked = document.querySelectorAll('input[name="b"]:checked').length;
    if (anySoldToAChecked > 0 || anySoldToBChecked > 0) {
      if (
        e.name === props.items.ticketDetails.soldToB &&
        e.name !== "None"
      ) {
        changeSoldToHandler("soldToB", "None", "B");
      }
      changeSoldToHandler("soldTo", e.name, "A");
    } else {
      toast.error('you must select at least (1) item above', { position: toast.POSITION.TOP_CENTER });
    }
  };

  const changeSoldToHandlerB = (e) => {
    let anySoldToAChecked = document.querySelectorAll('input[name="a"]:checked').length;
    let anySoldToBChecked = document.querySelectorAll('input[name="b"]:checked').length;
    console.log(anySoldToAChecked)
    console.log(anySoldToBChecked)
    if (anySoldToAChecked > 0 || anySoldToBChecked > 0) {
      changeSoldToHandler("soldToB", e.name, "B");
      if (
        e.name === props.items.ticketDetails.soldTo &&
        e.name !== "None"
      ) {//when soldTo A is set
        changeSoldToHandler("soldTo", "None", "A");
      }
    } else {
      toast.error('you must select at least (1) item above', { position: toast.POSITION.TOP_CENTER });
    }
  };

  const changeSoldToHandler = (name, value, previous = "") => {
    let body = {
      ticketId: props.match.params.id,
      [name]: value === "None" ? "" : value,
      previous: previous,
    };
    if (value === "None") {
      let key = name === "soldTo" ? "soldToAmount" : "soldToAmountB";
      body[key] = "0.00";
    }
    if (name === "soldToAmount") {
      body.profit =
        parseFloat(value) +
        parseFloat(props.items.ticketDetails.soldToAmountB) -
        parseFloat(props.items.ticketDetails.ticketAmount);
    } else if (name === "soldToAmountB") {
      body.profit =
        parseFloat(props.items.ticketDetails.soldToAmount) +
        parseFloat(value) -
        parseFloat(props.items.ticketDetails.ticketAmount);
    } else if (name === "soldTo" && (value === "None" || "")) {
      body.profit = "0.00";
    }
    PostApiWithAuthorizationAction(
      "metro-tickets/edit-items-soldTo-options",
      body
    )
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });

  };

  const toggledSoldTo = (e) => {
    if (e && soldToOptions.length === 1) {
      setDropLoad(true);
      GetApiActionWithAuthorization("common/get-all-buyers/true")
        .then((res) => {
          setDropLoad(false);
          res.data.push({ id: 0, name: 'None', enabled: true})
          setSoldToOptions(res.data);
        })
        .catch((err) => {
          setDropLoad(false);
          console.log(err);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  const proceedPrintLabelManually = (params) => {
    return params.copies > 0 ? PostApiWithAuthorizationAction("common/print-labels-manually", params)
    .catch((err) => {
      console.log(err);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    }) : Promise.resolve(false);
  }

  const printLabelsAndGoBack = (e) => {
    if (station && station.value) {
      proceedPrintLabelManually({
        copies,
        station: station.value,
        id: props.match.params.id,
        printLabelType
      }).then(() => {
        if (stationB && stationB.value && copiesB != "") {
          return proceedPrintLabelManually({
            copies: copiesB,
            station: stationB.value,
            id: props.match.params.id,
            soldToType: "B"
          })
        }
        return true
      }).then(() => {
        props.history.push("/portal/metro-tickets");
      })
    } else {
      toast.error("Please select a station", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const toggleBlock = (e) => {
    setBlockModal(!blockModal);
  };

  const copyToNew = (e) => {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
      race,
      sex,
      fromx,
    } = props.items.ticketDetails;
    let other = {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      race: raceOptions.find((fr) => fr.value === race),
      sex: sexOptions.find((fr) => fr.value === sex),
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
      fromx: { name: "repeat" },
    };
    if (copyTo.value === "ticket") {
      props.copyTicketDetailsToMetro(other);
      props.history.push("/portal/metro-tickets/add-new");
    } else {
      props.copyTicketDetailsToCoin(other);
      props.history.push("/portal/coin-tickets/add-new");
    }
  };

  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />

      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="bg-white d-flex align-items-center justify-content-between p-2">
            <h4>
              View Ticket Items (Amount - $
              {parseFloat(props.items.ticketDetails.ticketAmount).toFixed(2)})
              <br />
              <small>
                (Ticket ID:M{props.match.params.id} - Ticket Time:{" "}
                <Moment format="MMM Do YYYY HH:mm" tz="America/Los_Angeles">
                  {props.items.ticketDetails.ticketTime &&
                  props.items.ticketDetails.ticketTime.toString().length === 10
                    ? props.items.ticketDetails.ticketTime * 1000
                    : props.items.ticketDetails.ticketTime}
                </Moment>{" "}
                ){" "}
              </small>
            </h4>
            <div className="d-flex align-items-center">
              <div className="d-flex copy-drop justify-content-end align-items-center">
                <Label className="m-0">Copy to:</Label>
                <DropdownList
                  data={copyToData}
                  containerClassName="race-drop"
                  textField="label"
                  value={copyTo}
                  onChange={(e) => setCopyTo(e)}
                />
                <Button
                  className="mx-1"
                  color="info"
                  onClick={copyToNew}
                  // disabled={blocked}
                >
                  Copy
                </Button>
                <Button
                  color="warning"
                  className="m-0"
                  onClick={(e) => {
                    toggleBlock();
                  }}
                >
                  Block
                </Button>
              </div>
              {/* <Button
                type="button"
                color="warning"
                style={{
                  fontSize: "12px",
                  backgroundColor: "orange",
                  color: "black",
                }}
                className="h-fit ml-4 py-2 px-2"
                onClick={toggleConfirm}
              >
                <i className="fas fa-chevron-left"></i> Go Back
              </Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Div>
              <h4 className="mt-1 mb-1">Add New Items</h4>
              <AddMetroItems
                {...props}
              />
              <hr className="mt-1 mb-4" />
              <Row className="mb-2">
                <Col sm="4">
                  <h4 className="mt-1 mb-1">All Items</h4>
                </Col>
                <Col sm="8">
                  <div className="d-flex align-items-center justify-content-end">
                    <Form className="d-flex item-search align-items-center mr-4">
                      <InputGroup className="input-group-alternative border">
                        <Input
                          type={searchBy === "Weight" ? "number" : "text"}
                          placeholder="Search"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        {/* <Button color="primary">Search</Button> */}
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <Label className="form-control-label mb-0 ml-1">
                        By:
                      </Label>
                      <DropdownList
                        data={["Weight", "Description"]}
                        containerClassName="item-search-drop"
                        value={searchBy}
                        placeholder="Select"
                        onChange={(e) => setSearchBy(e)}
                      />
                    </Form>
                    <CustomInput
                      type="switch"
                      id={"editableSwitch"}
                      name="enabled"
                      checked={tableEditable}
                      onChange={(e) => setTableEditable(e.target.checked)}
                    >
                      {" "}
                      <small>Editable</small>
                    </CustomInput>
                  </div>
                </Col>
              </Row>
              
              {tableEditable ? (
                <ItemsEditableTable
                  {...props}
                  searchBy={searchBy.toLowerCase()}
                  search={search}
                  setUnauthorized={setUnauthorized}
                  highlightId={highlightId}
                />
              ) : (
                <ViewOnlyItems
                  search={search}
                  searchBy={searchBy.toLowerCase()}
                  {...props}
                  setUnauthorized={setUnauthorized}
                  highlightId={highlightId}
                />
              )}
            </Div>
            <Row className="soldTo mt-3 p-3">
              <Col sm={{ size: 7, offset: 5 }}>
                <Row className="align-items-center">
                  <Col xs="3" className="px-1 text-right">
                    <Label className="form-control-label">Sold To (A):</Label>
                  </Col>
                  <Col xs="4" className="p-0">
                    <DropdownList
                      data={soldToOptions}
                      textField="name"
                      containerClassName="sold-drop"
                      value={props.items.ticketDetails.soldTo}
                      busy={dropLoad}
                      onToggle={toggledSoldTo}
                      onChange={(e) => {
                        if(e.name === 'None') {
                          changeSoldToHandler("soldTo", "None", "A");
                        } else {
                          changeSoldToHandlerA(e);
                        }
                      }}
                    />
                  </Col>
                  {(((props.userType == 'admin') || (props.userType != 'admin' && firstEditSoldToAmount))  && (
                    <>
                      <Col xs="2" className="px-1  text-right">
                        <Label className="form-control-label">Amount:</Label>
                      </Col>
                      <Col xs="3" className="p-0">
                          <Input
                            type="number"
                            step="1"
                            name="soldToAmount"
                            placeholder=""
                            value={soldToAmount == 0 ? '' : soldToAmount}
                            onChange={(e) => setSoldToAmount(e.target.value)}
                            onBlur={(e) => {
                              changeSoldToHandler(e.target.name, e.target.value)
                              //
                              if (e.target.value > 0) {
                                setFirstEditSoldToAmount(false);
                              }
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={props.items.ticketDetails.soldTo === ""}
                          />
                      </Col>
                      </>
                    ))}
                </Row>
              </Col>
              <Col sm={{ size: 7, offset: 5 }} className="mt-2">
                <Row className="align-items-center">
                  <Col xs="3" className="px-1 text-right">
                    <Label className="form-control-label">Sold To (B):</Label>
                  </Col>
                  <Col xs="4" className="p-0">
                    <DropdownList
                      data={soldToOptions}
                      textField="name"
                      onToggle={toggledSoldTo}
                      value={props.items.ticketDetails.soldToB}
                      busy={dropLoad}
                      containerClassName="sold-drop"
                      onChange={(e) => {
                        if(e.name === 'None') {
                          changeSoldToHandler("soldToB", "None", "B");
                        } else {
                          changeSoldToHandlerB(e);
                        }
                      }}
                    />
                  </Col>
                  {(((props.userType == 'admin') || (props.userType != 'admin' && firstEditSoldToAmountB))  && (
                    <>
                      <Col xs="2" className="px-1 text-right">
                        <Label className="form-control-label">Amount:</Label>
                      </Col>
                      <Col xs="3" className="p-0">
                        <Input
                          type="number"
                          step="1"
                          name="soldToAmountB"
                          placeholder=""
                          value={soldToAmountB == 0 ? '' : soldToAmountB}
                          onChange={(e) => setSoldToAmountB(e.target.val)}
                          onBlur={(e) => {
                            changeSoldToHandler(e.target.name, e.target.value)
                              //
                              if (e.target.value > 0) {
                                setFirstEditSoldToAmountB(false);
                              }
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={props.items.ticketDetails.soldToB === ""}
                        />
                      </Col>
                    </>
                  ))}
                </Row>
              </Col>
              <Col
                // lg={{ size: 7, offset: 5 }}
                sm={{ size: 7, offset: 5 }}
                // xl={{ size: 5, offset: 7 }}
                className="mt-2"
              >
                  {props.userType &&
                  !["employee", "employees", "employe"].includes(
                    props.userType.toLowerCase()
                  ) ? (
                    <>
                    <Row className="align-items-center">
                      <Col xs="2" md="3" className="px-1 text-right">
                        <Label
                          className="form-control-label"
                          style={{verticalAlign: 'middle'}}>
                          Label copies:
                        </Label>
                      </Col>
                      <Col xs="2" md="2" className="p-0">
                        <DropdownList
                          data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                          value={copies}
                          onChange={(e) => setCopies(e)}
                          containerClassName="sold-drop"
                        />
                      </Col>
                      <Col xs="1" className="px-1 text-center">
                        <Label
                          className="form-control-label"
                          style={{verticalAlign: 'middle'}}>Station</Label>
                      </Col>
                      <Col xs="3" md="2" className="p-0">
                        <DropdownList
                          data={stationOptions}
                          value={station}
                          onChange={(e) => {
                            setStation(e);
                          }}
                          textField="label"
                          containerClassName="sold-drop"
                        />
                      </Col>
                    </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                      <Col
                        xs={{ size: 5 }}
                        className="px-1 text-right"
                      >
                        <Label className="form-control-label">
                          Label copies:
                        </Label>
                      </Col>
                      <Col xs="2" className="p-0">
                        <DropdownList
                          data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                          value={copies}
                          onChange={(e) => setCopies(e)}
                          containerClassName="sold-drop"
                        />
                      </Col>
                      </Row>
                    </>
                  )}
                {(props.items.ticketDetails
                  && props.items.ticketDetails.soldTo !== ""
                  && props.items.ticketDetails.soldToB !== '') && (
                  <>
                    <Row className="mt-1">
                      {props.userType &&
                      !["employee", "employees", "employe"].includes(
                        props.userType.toLowerCase()
                      ) ? (
                        <>
                          <Col xs="5" className="px-1 text-right">
                            <Label className="form-control-label">
                              Label copies (B):
                            </Label>
                          </Col>
                          <Col xs="2" className="p-0">
                            <DropdownList
                              data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                              value={copiesB}
                              onChange={(e) => setCopiesB(e)}
                              containerClassName="sold-drop"
                            />
                          </Col>
                          <Col xs="1" className="px-1 text-center">
                            <Label className="form-control-label">Station (B)</Label>
                          </Col>
                          <Col xs="3" className="p-0">
                            <DropdownList
                              data={stationOptions}
                              value={stationB}
                              onChange={(e) => {
                                setStationB(e);
                              }}
                              textField="label"
                              containerClassName="sold-drop"
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col
                            xs={{ size: 5}}
                            className="px-1 text-right"
                          >
                            <Label className="form-control-label">
                              Label copies (B):
                            </Label>
                          </Col>
                          <Col xs="2" className="p-0">
                            <DropdownList
                              data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                              value={copiesB}
                              onChange={(e) => setCopiesB(e)}
                              containerClassName="sold-drop"
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <div className="text-center mt-3">
              {(parseInt(copies) > 0 || parseInt(copiesB) > 0) ? (
                <Button
                  className="print-go-back"
                  color="primary"
                  onClick={printLabelsAndGoBack}
                >
                  Print Labels and Go back
                </Button>
              ) : (
                <Button
                  className="print-go-back"
                  color="warning"
                  style={{
                    fontSize: "12px",
                    backgroundColor: "orange",
                    color: "black",
                  }}
                  onClick={toggleConfirm}
                >
                  Go back
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
      {blockModal && (
        <BlockModal
          show={blockModal}
          toggle={toggleBlock}
          setBlock={(e) => {}}
          data={{
            firstName: props.items.ticketDetails.firstName,
            lastName: props.items.ticketDetails.lastName,
            ticketId: props.match.params.id,
          }}
        />
      )}

      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          message={
            <h4>
              Unsaved data will be lost! Are you sure you want to go back?
            </h4>
          }
          toggle={toggleConfirm}
          confirmed={() => props.history.goBack()}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userType: state.loginUserReducer.userType,
    items: state.ticketReducer.tickets_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTicketItemsListing: (id) => dispatch(fetchTicketItemsListing(id)),
    fetchTicketItemsListingSuccess: (body) =>
      dispatch(fetchTicketItemsListingSuccess(body)),
    copyTicketDetailsToMetro: (body) =>
      dispatch(copyTicketDetailsToMetro(body)),
    copyTicketDetailsToCoin: (body) => dispatch(copyTicketDetailsToCoin(body)),
    resetState: () => dispatch(resetState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewItems);

const Div = styled.div`
  min-height: 200px;
`;
